import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { StoreService } from '@app/services/store/store.service';
import { ApiService } from '@app/services/api/api.service';
import { IUserToken } from '@app/models/user';
import { Observable, catchError, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthLazyGuard implements CanLoad {
  private token: IUserToken;

  constructor(
    private router: Router,
    private store: StoreService,
    private api: ApiService,
  ) {
    this.store.state$.subscribe(async state => {
      this.token = state.token;
    });
  }

  canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.token) {
      this.router.navigateByUrl('auth/login');
      return false;
    }
    return this.api.retrieveLoggedUserOb(this.token).pipe(
      switchMap(async (user) => {
        // await this.layoutService.setUISettingFromUserInfo(user);
        return !!user;
      }), catchError((err) => {
      this.store.clearStoreAndLogout();
      return throwError(() => err)
    }))
  }
}
